/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Card, Container, Grid, Typography,
} from '@mui/material';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import Layout, { LayoutContext } from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import howItWorks from '../img/how-it-works.png';
import howItWorksFlag from '../img/how-it-works-flag.png';

// eslint-disable-next-line
export const ProductPageTemplate = ({
  title,
  heading,
  description,
  intro,
  // main,
  // testimonials,
  // fullImage,
  // pricing,
}) => {
  const { mobileMode } = React.useContext(LayoutContext);

  return (
    <>
      <Container
        maxWidth="none"
        sx={{ backgroundImage: `url(${howItWorks})`, backgroundSize: 'cover', height: 200 }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          sx={{ height: '100%' }}
        >
          <Typography
            variant="h2"
            sx={{
              color: 'white',
              fontFamily: 'Poppins',
              fontSize: '36px',
              fontWeight: '600',
              lineHeight: '54px',
              letterSpacing: '0.045em',
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        alignItems="center"
        rowGap={mobileMode ? 3 : 6}
        sx={mobileMode ? { py: 5, px: 2 } : { pt: 8, pb: 12, px: 4 }}
      >
        <Typography
          variant="h3"
          sx={
            mobileMode
              ? {
                fontFamily: 'Poppins',
                fontSize: '32px',
                fontWeight: '600',
                lineHeight: '43px',
                letterSpacing: '0em',
                textAlign: 'center',
              }
              : {
                fontFamily: 'Source Sans Pro',
                fontSize: '48px',
                fontWeight: '600',
                lineHeight: '64px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#263238',
                px: 40,
              }
          }
        >
          {heading}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            fontFamily: 'Poppins',
            fontSize: '22px',
            fontWeight: '400',
            lineHeight: '48px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#000F2080',
            px: mobileMode ? 2 : 8,
          }}
        >
          {description}
        </Typography>
        <Grid
          container
          columnGap={2}
          rowGap={3}
          sx={mobileMode ? { px: 3 } : { pt: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={3.5}>
            <Grid container direction="column" rowGap={mobileMode ? 3 : 2}>
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[0]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[0].text}
                  </Typography>
                </Grid>
              </Card>
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[1]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[1].text}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Grid
              container
              direction="column"
              rowGap={mobileMode ? 3 : 2}
              alignContent="center"
              alignItems="center"
            >
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[2]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[2].text}
                  </Typography>
                </Grid>
              </Card>
              {!mobileMode && (
                <img
                  src={howItWorksFlag}
                  height="260px"
                  width="230px"
                  style={{ paddingTop: '2vh' }}
                  alt="How it works flag"
                />
              )}
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[3]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[3].text}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Grid container direction="column" rowGap={mobileMode ? 3 : 2}>
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[4]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[4].text}
                  </Typography>
                </Grid>
              </Card>
              <Card
                sx={{
                  backgroundColor: '#5EBC67',
                  borderRadius: '27px',
                  p: 4,
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowGap={2}
                  sx={mobileMode ? { height: '300px' } : { minHeight: '320px' }}
                  justifyContent="center"
                >
                  <div style={{ height: '96px', width: '96px' }}>
                    <PreviewCompatibleImage imageInfo={intro.blurbs[5]} alt="Double Check Mark" />
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '32px',
                      fontWeight: '500',
                      lineHeight: '48px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                    }}
                  >
                    {intro.blurbs[5].text}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
ProductPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

function ProductPage({ data }) {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GatsbySeo
        title="Who It's For | Squaddie - The Caddie for your Golf Squad"
        description="Discover who our golf foursome availability scheduler app is perfect for. From avid golfers to weekend warriors, our app makes scheduling availabilities with your golf group a breeze. Learn more now!"
        canonical="https://www.squaddie.co/who-its-for"
      />

      <JsonLd
        json={{
          context: 'https://schema.org',
          url: 'https://www.squaddie.co/who-its-for',
          images: frontmatter.intro.blurbs.map((x) => x.image),
          datePublished: '2023-03-10T08:00:00+08:00',
          // dateModified="2023-03-10T09:00:00+08:00"
          authorName: 'Squaddie',
          description:
            'Discover who our golf foursome availability scheduler app is perfect for. From avid golfers to weekend warriors, our app makes scheduling availabilities with your golf group a breeze. Learn more now!',
          '@type': 'SoftwareApplication',
          name: 'Squaddie',
          applicationCategory: 'UtilitiesApplication',
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '5.0',
            ratingCount: '1',
          },
          offers: {
            '@type': 'Offer',
            price: '0',
          },
        }}
      />
      <ProductPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        // main={frontmatter.main}
        // testimonials={frontmatter.testimonials}
        // fullImage={frontmatter.full_image}
        // pricing={frontmatter.pricing}
      />
    </Layout>
  );
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 96, quality: 100, layout: CONSTRAINED)
              }
            }
            text
          }
        }
      }
    }
  }
`;
